<template>
  <div>
    <div class="forgot-password">
      <div class="content">
        <div class="card-form">
          <form @submit.prevent="onSubmit">
            <div class="title-form text-center">
              <div class="welcome-text">Masukkan Kode OTP</div>
            </div>

            <div class="row mb-3">
              <div
                class="col-md-2"
                v-for="(dataotp, index) in dataOTP"
                :key="index"
              >
                <div class="form-group mt-5">
                  <input
                    type="text"
                    v-model="this.dataOTP[index]"
                    @change="checkForm"
                    class="form-control"
                    @keyup="setOTP"
                    :ref="'otp-' + index"
                    :data-index="index"
                    aria-label="OTP"
                    maxlength="1"
                  />
                </div>
              </div>
            </div>

            <div class="desc-text mb-5">
              Kode OTP terkirim ke alamat email
              <span style="color: #0e867f; font-weight: 500">{{
                this.convertEmailText(this.formData.email)
              }}</span>
              Tidak menerima kode OTP ?
              <button class="btn" type="button" @click="reSendEmail">
                Klik di sini.
              </button>
            </div>

            <button
              class="btn btn-form mt-2 mb-4 d-block"
              id="submitForm"
              :disabled="isSubmit || buttonDisabled"
            >
              <i class="fas fa-spinner fa-spin" v-if="isSubmit"></i>
              <span class="btn-login">Lanjut</span>
            </button>
            <div class="text-center">
              <span class="text-link">
                Kembali ke halaman
                <router-link to="/">Login </router-link>
              </span>
            </div>
          </form>
        </div>
        <div class="element-img">
          <img src="../assets/img/ball.svg" alt="" />
          <img src="../assets/img/square-3d.svg" alt="" />
          <img src="../assets/img/square.svg" alt="" />
          <img src="../assets/img/square.svg" alt="" />
          <img src="../assets/img/elipse.svg" alt="" class="" />
          <img src="../assets/img/elipse.svg" alt="" class="" />
          <div class="title-brand">Finanta</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post_CheckOTP, post_ForgotPassword } from "../actions/auth";
import { checkRules } from "../helper";
export default {
  name: "verifikasi",
  data() {
    return {
      formData: {
        email: localStorage.getItem("_Fem"),
        otp: "",
      },
      dataOTP: {
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
      },
      formError: [],
      isSubmit: false,
      rules: {
        otp: {
          required: true,
          minLength: 6,
        },
      },
      submited: false,
      buttonDisabled: true,
    };
  },
  methods: {
    checkForm: async function () {
      if (this.submited) {
        var check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },
    setOTP: function (e) {
      this.rules.otp.changed = true;
      var charCode = e.which ? e.which : e.keyCode;
      var check = true;
      if (
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 8 &&
        charCode === 32
      ) {
        check = false;
        e.preventDefault();
      } else if (charCode === 32) {
        check = false;
        e.preventDefault();
      }
      var target = e.currentTarget ? e.currentTarget : e.target;
      var index = parseInt(target.getAttribute("data-index"));
      var otp = "";
      for (const key in this.dataOTP) {
        otp += this.dataOTP[key];
      }
      this.formData.otp = otp;
      this.buttonDisabled = otp.length == 6 ? false : true;
      var to = "";
      if (charCode == 46 || charCode == 8) {
        to = index - 1;
      } else if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 96 && charCode <= 105)
      ) {
        to = index + 1;
      }
      if (check && to >= 0 && to <= 5 && this.$refs["otp-" + to]) {
        this.$refs["otp-" + to][0].focus();
      }
      return true;
    },
    convertEmailText: (text) => {
      var split = text.split("@");
      var last = split[split.length - 1];
      delete split[split.length - 1];
      var text_email = split.join("");
      return text_email.substr(0, 4) + "*******@" + last;
    },
    showWarningAlert(msg = "", callaback) {
      var _msg = msg ? msg : "Terjadi kesalahan, silakan ulangi kembali";
      this.$swal({
        html:
          '<div style="color:#222222; font-size:20px; font-weight:500;" class="mt-3 mb-3">GAGAL!</div>' +
          '<div style="color:#263238" font-size:12px;">' +
          _msg +
          "</div>",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: "#fff",
        cancelButtonText: "Tutup",
      }).then(function () {
        if (typeof callaback === "function") {
          callaback();
        }
      });
    },
    showAlert(title = "", msg, callaback) {
      var _msg = msg ? msg : "Kode otp terkirim, mohon cek kotak masuk";
      // Use swtalert2
      // Kode OTP terkirim ke nomor telepon <span style="color:#0E867F"> 0822******09 </span> mohon cek kotak masuk
      this.$swal({
        html:
          '<div style="color:#222222; font-size:20px; font-weight:500;" class="mt-3 mb-3">' +
          title +
          "</div>" +
          '<div style="color:#263238" font-size:12px;">' +
          _msg +
          "</div>",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#fff",
        confirmButtonText: "Tutup",
      }).then(function () {
        if (typeof callaback === "function") {
          callaback();
        }
        // window.location = "/verifikasi";
      });
    },
    reSendEmail: async function () {
      await post_ForgotPassword(
        this.formData,
        () => {
          this.isSubmit = false;
          this.showAlert(
            "OTP Terkirim!",
            `Kode OTP terkirim ke alamat email <span style="color:#0E867F"> ${this.convertEmailText(
              this.formData.email
            )} </span> mohon cek kotak masuk atau spam`,
            () => {
              localStorage.setItem("_Fem", this.formData.email);
            }
          );
        },
        (err) => {
          this.isSubmit = false;
          this.showWarningAlert();
          console.log(err);
        }
      );
    },
    onSubmit: async function () {
      this.submited = true;
      var check = await checkRules(this.rules, this.formData);
      if (!check.success) {
        this.formError = check.error;
      } else {
        this.isSubmit = true;
        await post_CheckOTP(
          this.formData,
          (res) => {
            this.isSubmit = false;
            localStorage.setItem("_AcTo", res.access_token);
            this.$router.push({ name: "newPassword" });
          },
          () => {
            this.isSubmit = false;
            this.showWarningAlert(
              "Kode OTP salah atau Kode OTP telah kedaluwarsa",
              () => {
                this.dataOTP = { 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" };
                this.buttonDisabled = true;
                this.formData.otp = "";
              }
            );
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.form-group input:focus {
  border-bottom: #000 1px solid;
  box-shadow: none !important;
}
.form-group {
  margin-bottom: 20px;
}

.desc-text .btn {
  margin: 0px;
  padding: 0px;
  color: #0e867f;
  font-weight: 500;
}

.form-group input {
  color: #000;
  font-weight: 600;
  font-size: 30px;
}
.logo-form img {
  width: 150px;
}

.title-brand {
  position: absolute;
  color: #fff;
  font-size: 54px;
  font-weight: bold;
  top: 2%;
  left: 5%;
}

.element-img img:nth-child(1) {
  position: absolute;
  top: 10%;
  left: 25%;
  z-index: 1;
}

.element-img img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 60%;
  z-index: 1;
}

.element-img img:nth-child(3) {
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 0;
}

.element-img img:nth-child(4) {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.element-img img:nth-child(5) {
  position: absolute;
  left: -150px;
  top: 60%;
  z-index: 1;
}

.element-img img:nth-child(6) {
  position: absolute;
  right: -150px;
  top: 10%;
  z-index: 1;
}

.text-link {
  color: #263238;
}

.text-link a {
  text-decoration: none;
  color: #40ddd4;
}

.title-form .welcome-text {
  font-size: 28px;
  color: var(--text);
  font-weight: 600;
  text-align: center;
}

.desc-text {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
}

.forgot-password {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
}

.content {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.forgot-password .card-form {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  padding: 50px 50px;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  box-shadow: 0px 0px 48px -20px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.forgot-password .form-control {
  color: var(--text);
  border-radius: 0px;
  text-align: center;
  padding: 9px 16px;
  font-family: "Poppins", sans-serif;
  border-left: none;
  border-top: none;
  border-right: none;
}

.swal2-popup {
  width: 24em !important;
}

.btn-form {
  background-color: #40ddd4;
  width: 100%;
  color: #fff;
}

.forgot-password .btn-form:hover {
  color: #fff !important;
}

/* .btn:hover {
    color: #fff !important;
  } */
</style>
